import React, { Component } from "react";
import Layout from "../../components/layout";
import Breadcrumbs from "../../components/breadcrumbs";
import CustomHelmet from "../../components/customHelmet";
//import PageHeading from "../../../components/pageHeading";
import ReCAPTCHA from "react-google-recaptcha";
import { host, reCaptchaKey } from "../../helpers/commons";

import cx from "classnames";
import Styles from "./assets/BrokerLogin.module.css"

class BrokerLogin extends Component {
    constructor(props) {
        super(props);
        //const params = new URLSearchParams(window.location.search);

        this.state = {
            email: "",
            otp: "",
            otpSent: false,
            otpVerified: false,
            loading: false,
            message: "",
            messageClass: "",
            profile_pic: "https://marketplace.hashfinancialgroup.com/site-assets/profile-pic.png?v=1.0",
            recaptchaToken: null,
            resendTimer: 0
        };
        this.recaptchaRef = React.createRef();
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleCaptchaChange = (token) => {
        this.setState({ recaptchaToken: token });
    };

    sendOTP = async () => {
        const { email, recaptchaToken } = this.state;

        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            this.setState({ message: "Please enter a valid email." });
            return;
        }

        if (!recaptchaToken) {
            this.setState({ message: "Please verify the CAPTCHA." });
            return;
        }

        this.setState({ loading: true, message: "" });

        try {
            const response = await fetch(host + "/gatsby-api/send-otp", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: `email=${encodeURIComponent(email)}&g-recaptcha-response=${recaptchaToken}`
            });

            const data = await response.json();
            if (data.success) {
                this.setState({ otpSent: true, message: "OTP sent to your email.", resendTimer:3, messageClass: "alert-info" });
                
                let countdown = 180;

                const timer = setInterval(() => {
                    let minutes = Math.floor(countdown / 60);
                    let seconds = countdown % 60;
                    
                    let displayTime = `${minutes}:${seconds.toString().padStart(2, "0")}`;
                
                    //console.log(displayTime); 
        
                    this.setState({ resendTimer: displayTime});
        
                    countdown--; 
                    
                    if (countdown <= 0) {
                        clearInterval(timer); 
                        this.setState({ resendTimer: 0});
                    }
                }, 1000);  
            } else {
                this.setState({ message: data.data.message || "Failed to send OTP.", messageClass: "alert-danger"  });
            }
        } catch (error) {
            this.setState({ message: "Error sending OTP. Please try again." });
        }

        this.setState({ loading: false });
        if (this.recaptchaRef.current) this.recaptchaRef.current.reset();
    };

    verifyOTP = async () => {
        const { email, otp } = this.state;

        this.setState({ loading: true, message: "" });

        try {
            const response = await fetch(host + "/gatsby-api/validate-otp", {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: `email=${encodeURIComponent(email)}&otp=${encodeURIComponent(otp)}`
            });

            const data = await response.json();

            // console.log(">>>>>>");
            // console.log(JSON.stringify(data));
            // console.log(">>>>>>"); 
            // alert('check console');

            if (data.success) {
                // localStorage.setItem("isAuthenticated", "true");
                document.cookie = `broker_session=${data.tid}; path=/; domain=.hashfinancialgroup.com; secure; HttpsOnly; samesite=None;`;
                this.setState({ otpVerified: true, message: "" });
                window.location.href = "/broker/profile";
            } else {
                this.setState({ message: data.message || "Invalid OTP.", messageClass: "alert-danger" });
            }
        } catch (error) {
            this.setState({ message: "Error verifying OTP.", messageClass: "alert-danger"  });
        }

        this.setState({ loading: false });
    };

    async componentDidMount() {
        // try {
        //     const response = await fetch(host + "/gatsby-api/validate-session", {
        //         method: "POST",
        //         headers: {
        //             "Content-Type": "application/json",
        //             "Authorization": `Bearer ${sessionToken}`
        //         }
        //     });

        //     const data = await response.json();
        //     if (data.success) {
        //         this.setState({ isLoggedIn: true, brokerData: data.broker, loading: false });
        //         window.location.href = "/broker/profile";
        //     } else {
        //         this.cookies.remove("broker_session");
        //         this.setState({ isLoggedIn: false, loading: false });                
        //     }
        // } catch (error) {
        //     this.setState({ isLoggedIn: false, loading: false, message: "Error fetching profile." });
        // }

        if (typeof window !== "undefined") { 
            const params = new URLSearchParams(window.location.search);
            this.setState({ email: params.get("email") || "" });
        }

        try {
            const response = await fetch(host + "/gatsby-api/validate-session", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            const data = await response.json();

            if (data.success) {
                // this.setState({ isLoggedIn: true, brokerData: data.data, loading: false });
                window.location.href = "/broker/profile";
            } else {
                this.setState({ isLoggedIn: false, loading: false });
            }
        } catch (error) {
            this.setState({ isLoggedIn: false, loading: false, message: "Something went wrong.", messageClass: "alert-danger"  });
        }
    }

    render() {
        const { email, otp, otpSent, resendTimer, otpVerified, loading, message, messageClass } = this.state;
        let breadcrumbs = [{ name: "Broker Login", link: "" }];   

        return (
            <Layout>
                <CustomHelmet>
                    <title>Broker Login</title>
                    <meta name="description" content="Login to access your broker profile." />
                </CustomHelmet>
                {/* <Breadcrumbs breadcrumbs={breadcrumbs} /> */}
                <div className={cx(Styles.loginPanel, "container")}>
                    <div className="row">
                        <div className="col-md-4 mx-auto">
                            <div className="card">
                                <div className={cx(Styles.cardHeader,"card-header text-center")}>
                                    <img className={Styles.profilePic} src={this.state.profile_pic} />
                                    <h3 className="card-title">Welcome Back!</h3>
                                    <p>Verify your email address to proceed.</p>
                                </div>
                                <div className="card-body">
                                    
                                    
                                    {!otpVerified ? (
                                        <> 
                                            <div className="form-group">
                                                <label>Email:</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={this.handleInputChange}
                                                    disabled={otpSent}
                                                />
                                            </div>

                                            {!otpSent && (
                                                <>
                                                    <ReCAPTCHA
                                                        sitekey={reCaptchaKey}
                                                        onChange={this.handleCaptchaChange}
                                                        ref={this.recaptchaRef}
                                                    />
                                                    <button
                                                        className="btn btn-primary mt-1 mb-3 w-100"
                                                        onClick={this.sendOTP}
                                                        disabled={loading}
                                                    >
                                                        {loading ? "Sending OTP..." : "Send OTP"}
                                                    </button>
                                                </>
                                            )}

                                            {otpSent && (
                                                <div>
                                                    <div className="form-group mt-3">
                                                        <label>Enter OTP:</label>
                                                        <input
                                                            type="text"
                                                            name="otp"
                                                            className="form-control"
                                                            value={otp}
                                                            onChange={this.handleInputChange}
                                                        />
                                                    </div>

                                                    <button
                                                        className="btn btn-success w-100"
                                                        onClick={this.verifyOTP}
                                                        disabled={loading}
                                                    >
                                                        {loading ? "Verifying OTP..." : "Verify OTP"}
                                                    </button>
                                                    <p className="text-center">
                                                        {resendTimer != 0 ? (<button 
                                                            className={cx("btn mt-1", Styles.btnResend)}
                                                            disabled="true"
                                                        >
                                                            Resend in { resendTimer } mins
                                                        </button>) : (
                                                            <button 
                                                            className={cx("btn mt-1", Styles.btnResend)}
                                                            onClick={this.sendOTP}
                                                        >
                                                            Resend OTP
                                                        </button>
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="alert alert-success">You are logged in!</div>
                                    )}

                                    {message && <div className={cx(messageClass, "alert")}>{message}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default BrokerLogin;
